<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <AMonthPicker
                    class="mr-2 mt-2"
                    v-model:value="state.params.month"
                    placeholder="Pilih Bulan"
                    style="width:300px"
                    format="MMMM YYYY"/>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:kabupaten="state.params.kabupaten"/>
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterToko
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.toko"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="card mt-5">
            <div class="card-body">
                <div class="row justify-content-end">
                    <div class="col-lg-6 col-md-12">
                        <strong>Toko Area Aktif</strong>
                    </div>
                    <div class="col-lg-6 col-md-12 text-right mt-auto">
                        <DownloadExcel
                            :url="state.endpoint.data_area"
                            :params="state.params"
                            namefile="Toko-Area-Aktif"
                            @errors="errorMessage" />
                    </div>
                </div>
                <AreaBarChart
                    :data="state.area"
                    :loading="state.loadingArea"/>
            </div>
        </div>
        
        <div class="card mt-5">
            <div class="card-body">
                <div class="row justify-content-end">
                    <div class="col-lg-6 col-md-12 mt-auto">
                    </div>
                    <div class="col-lg-6 col-md-12 text-right mt-auto">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="state.params"
                            namefile="Monitor-Toko-Belum-Transaksi"
                            @errors="errorMessage" />
                    </div>
                </div>

                <!-- list table -->
                <div class="table-responsive text-nowrap mt-4">
                    <MdTable
                        :columns="state.columns"
                        :data-source="state.data"
                        size="small"
                        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                        :pagination="{
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                            showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                            total: state.meta.total,
                            pageSize: state.meta.per_page,
                            current: state.meta.page,
                        }"
                        @change="handleTableChange"
                        :loading="state.isFetching">
                    </MdTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch, onBeforeMount } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import AreaBarChart from './AreaBarChart'
import { debounce, pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterDistributor,
        FilterRegional,
        FilterArea,
        FilterToko,
        AreaBarChart,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'ID Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Nama Pemilik Toko',
                    dataIndex: 'owner',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributor_name',
                },
                {
                    title: 'Regional Toko',
                    dataIndex: 'regional',
                },
                {
                    title: 'Area Toko',
                    dataIndex: 'area',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'provinsi',
                },
                {
                    title: 'Kabupaten',
                    dataIndex: 'kabupaten',
                },
                {
                    title: 'Kecamatan',
                    dataIndex: 'kecamatan',
                },
                {
                    title: 'Cluster',
                    dataIndex: 'cluster',
                },
            ],
            endpoint: {
                current: '/api/report/metabase-v2/monitoring-toko-belum-transaksi',
                data_area: `/api/report/metabase-v2/grafik-monitoring-toko-belum-transaksi`,
            },
            data: [],
            area: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            loadingArea: false,
            params: {
                q: '',
                region: [],
                distributor: [],
                area: [],
                kabupaten: [],
                brand: [],
                toko: [],
                month: moment(new Date()).startOf('month').format('YYYY-MM'),
                ton: "<1",
                page: 1,
                "per-page": 10,
            },
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            btnFetchData()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.month) {
                params.month = moment(state.params.month).format('YYYY-MM')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const fetchDataArea = () => {
            state.loadingArea = true
            apiClient
                .get(state.endpoint.data_area, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    state.area = data
                })
                .catch(error => console.error(error))
                .finally(() => {
                    state.loadingArea = false
                })
        }

        const btnFetchData = () => {
            fetchDataList()
            fetchDataArea()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            handleTableChange,
            errorMessage,
        }
    },
})
</script>

<style lang="scss" scoped>
.content-card {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.card {
    height: 100%;
}
</style>